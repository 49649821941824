import './styles.scss';

export default function Disclaimer() {
  return (
    <div className="disclaimer-container">
      <h2 className="disclaimer-heading">Medical Information Sources</h2>
      <p className="disclaimer-text">
        Blood Pressure Information
      </p>
      <p className="disclaimer-text">
        Blood pressure readings and ranges are based on guidelines from the American Heart Association (2024). 
        Learn more at: <a href="https://www.heart.org/bloodpressure" target="_blank" rel="noopener noreferrer" className="disclaimer-link">www.heart.org/bloodpressure</a>
      </p>

      <h3 className="disclaimer-subheading">Medical Disclaimer</h3>
      <p className="disclaimer-text">
        Important Notice: Please Read Carefully
      </p>
      <p className="disclaimer-text">
        This app is designed to help you track your health metrics related to diabetes management. It is not intended to replace professional medical advice, diagnosis, or treatment.
      </p>

      <h4 className="disclaimer-subheading">Data Collection Requirements:</h4>
      <ul className="disclaimer-list">
        <li className="disclaimer-list-item">
          Blood pressure readings must be obtained using FDA-approved blood pressure monitoring devices
        </li>
        <li className="disclaimer-list-item">
          Blood glucose readings must be obtained using proper glucose meters or continuous glucose monitoring devices
        </li>
        <li className="disclaimer-list-item">
          This app does not measure any health metrics directly - it only stores and displays data that you manually enter from your medical devices
        </li>
      </ul>

      <h4 className="disclaimer-subheading">App Usage:</h4>
      <ul className="disclaimer-list">
        <li className="disclaimer-list-item">
        All data displayed is either directly entered by you from your medical devices 
        </li>
        <li className="disclaimer-list-item">
        The information provided by this app is for tracking and informational purposes only
        </li>
        <li className="disclaimer-list-item">
        The app does not validate the accuracy of entered data - it is your responsibility to ensure accurate readings from your medical devices
        </li>
      </ul>

      <h4 className="disclaimer-subheading">Medical Guidance:</h4>
      <ul className="disclaimer-list">
        <li className="disclaimer-list-item">
        Never disregard professional medical advice or delay seeking it because of something you have read or tracked in this app
        </li>
        <li className="disclaimer-list-item">
        Blood pressure and blood glucose readings shown are your last logged values and should be reviewed with your healthcare provider
        </li>
      </ul>

      <h4 className="disclaimer-subheading">Always consult with your physician or other qualified healthcare provider:</h4>
      <ul className="disclaimer-list">
        <li className="disclaimer-list-item">
        Before starting any diet or exercise program
        </li>
        <li className="disclaimer-list-item">
        When interpreting your health metrics
        </li>
        <li className="disclaimer-list-item">
        For any questions about a medical condition
        </li>
        <li className="disclaimer-list-item">
        Before making any changes to your medical care
        </li>
        <li className="disclaimer-list-item">
        For proper use of medical devices and interpretation of their readings
        </li>
      </ul>

      <h4 className="disclaimer-subheading">This app does not:</h4>
      <ul className="disclaimer-list">
        <li className="disclaimer-list-item">
        Measure any health metrics directly
        </li>
        <li className="disclaimer-list-item">
        Provide medical advice
        </li>
        <li className="disclaimer-list-item">
        Make treatment recommendations
        </li>
        <li className="disclaimer-list-item">
        Replace your healthcare provider
        </li>
        <li className="disclaimer-list-item">
        Diagnose medical conditions
        </li>
        <li className="disclaimer-list-item">
        Validate the accuracy of medical devices
        </li>
      </ul>

      <p className='disclaimer-copyright'>© 2024 FundusAI. Last updated: 27th November, 2024 </p>
    </div>
  );
}