import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";
import Aos from "aos";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";

import "aos/dist/aos.css";
import "./index.scss";
import "ckeditor5/ckeditor5.css";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Footer from "./Components/Footer";
import Chat from "./Pages/Chat";
import Disclaimer from "./Pages/Disclaimer";
import TermsOfUse from "./Pages/TermsOfUse";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <></>;
}
const App: React.FC = () => {
  Aos.init();
  return (
    <>
      <MantineProvider>
        <Notifications />
        <Router>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/medical-disclaimer" element={<Disclaimer />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </Router>
      </MantineProvider>
    </>
  );
};
export default App;
