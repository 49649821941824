import { Link, useLocation } from "react-router-dom";

import { Container, Grid, GridProps } from "@mui/material";
import { Flex, Text } from "@mantine/core";

import Logo from "../../Assets/IMG/Logo.png";

import EnvelopeIcon from "../../Assets/IMG/Footer/Envelope.svg";
import TelephoneIcon from "../../Assets/IMG/Footer/Telephone.svg";
import HomeIcon from "../../Assets/IMG/Footer/Home.svg";
import InstagramIcon from "../../Assets/IMG/Footer/Instagram.svg";
import TwitterIcon from "../../Assets/IMG/Footer/Twitter.svg";
import FacebookIcon from "../../Assets/IMG/Footer/Facebook.svg";
import AboutUsIcon from "../../Assets/IMG/Navbar/AboutUs.svg";

import "./styles.scss";
import { IconBrandLinkedin } from "@tabler/icons-react";
export default function Footer() {
  const footerMenuItems = [
    {
      title: "Product",
      items: [
        { label: "Features", link: "" },
        { label: "Pricing", link: "" },
        { label: "Case Studies", link: "" },
        { label: "Reviews", link: "" },
        { label: "Updates", link: "" },
      ],
    },
    {
      title: "Company",
      items: [
        { label: "About", link: "about" },
        { label: "Contact Us", link: "" },
        { label: "Careers", link: "" },
        { label: "Culture", link: "" },
        { label: "Blog", link: "" },
      ],
    },
    {
      title: "Support",
      items: [
        { label: "Getting Started", link: "" },
        { label: "Help center", link: "" },
        { label: "Server status", link: "" },
        { label: "Report a bug", link: "" },
        { label: "Chat support", link: "" },
      ],
    },
  ];
  const gridItemProps: GridProps = {
    item: true,
    xl: 4,
    lg: 4,
    md: 6,
    sm: 6,
    xs: 6,
  };
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.replaceAll("/", "");
  return (
    <>
      {path !== "chat" && (
        <div className="footer-container" id="contact">
          <Container maxWidth="lg" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <img src={Logo} className="logo" alt="" />
            <div className="footer flex-row justify-between width-100">
              <div className="left flex-col">
                <Text className="header">Get in touch</Text>
                <div className="item flex-row align-center">
                  <img src={EnvelopeIcon} alt="" className="icon" />
                  <a href="mailto:info@fundusai.com">
                    <Text className="label">Info@fundusai.com</Text>
                  </a>
                </div>
                <div className="item flex-row align-center">
                  <img src={TelephoneIcon} alt="" className="icon" />
                  <a href="tel:+2348108273624">
                    <Text className="label">+234 909 006 9112</Text>
                  </a>
                </div>
                <div className="item flex-row align-center">
                  <img src={HomeIcon} alt="" className="icon" />
                  <Text className="label">
                    2nd floor, National Center for Artificial Intelligence and
                    Robotics, Wuye, Abuja.
                  </Text>
                </div>
                <div className="social flex-row align-center">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/fundusai"
                  >
                    <img src={InstagramIcon} alt="" className="social-icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/profile.php?id=100084004540042&mibextid=LQQJ4d"
                  >
                    <img src={FacebookIcon} alt="" className="social-icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://x.com/fundusai"
                  >
                    <img src={TwitterIcon} alt="" className="social-icon" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://ng.linkedin.com/company/fundusai"
                  >
                    <IconBrandLinkedin
                      color="var(--blue-secondary)"
                      style={{ marginLeft: -10 }}
                      size={25}
                    />
                  </a>
                </div>
              </div>
              <Grid
                className="right"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {footerMenuItems.map((footerMenu, index) => {
                  return (
                    <Grid key={footerMenu.title} {...gridItemProps}>
                      <div className="col flex-col">
                        <div className="col-items flex-col">
                          <Text className="col-header">{footerMenu.title}</Text>
                          {footerMenu.items.map((item) => {
                            return (
                              <a
                                href="#"
                                className="footer-link"
                                key={item.label}
                              >
                                {item.label}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div className="bottom flex-row align-center justify-between width-100">
              <Text className="copy">&copy; Copyright 2024. All Rights Reserved</Text>
              <div className="flex-row row align-center">

                <Link to="/terms-of-use">
                  <Text className="label">Terms and Conditions</Text>
                </Link>
                <span className="divider">|</span>
                <Link to="/privacy-policy">
                  <Text className="label">Privacy Policy</Text>
                </Link>
                <span className="divider">|</span>
                <Link to="medical-disclaimer">
                  <Text className="label">Disclaimer</Text>
                </Link>
              </div>
            </div>
          </Container>
        </div>

      )}
    </>
  );
}
